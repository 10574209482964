import React, { useState, useEffect, useRef, useCallback } from 'react'
//import { useHistory } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/esm/Card'
import Modal from 'react-bootstrap/Modal'
import CloseButton from 'react-bootstrap/CloseButton'

import './Waiver.scss'

/** Requires the following parameters
 * @param msg -String = the message that gives the waiver warning (such as "You have not signed....")
 * @param user -Object = takes in a user that has a .id to find the proper waiver.  
 * @param signedWaiver - Boolean =  If user doesn't have the has_signed_waiver (such as the partial user from POS)  
 * @param btntxt - String = the text for the button that displays
 * @param hide -Boolean = on true -will entirely hide the component if waiver is signed on false-will display "Signed Waiver on File"
 * @param reset - function = If props.hide is false or not passed in, then a reset function is reccomended to reset the state of the user to reflect change in waiver status
 */
export const Waiver = (props) => {
    const reset=props.reset;

//#startregion useState, useEffect

    //const history = useHistory();
    const { REACT_APP_API_URL } = process.env;
    const mountedRef = useRef(false);
    const [ waiverHideShow, setWaiverHideShow ]=useState(false);
    const [ waiver, setWaiver ]=useState(null);
    const [ signedWaiver, setSignedWaiver ]=useState(null);
    const [ loading, setLoading ]=useState(true);
    const [ waiverClassName, setWaiverClassName ]=useState('waiver-home-card')

    const handleIframeTask=useCallback((e)=>{
        if (e.data === 'submitted form'){
            debugger
            setWaiverHideShow(false);
            setSignedWaiver(true)
            if (reset) reset(); //refresh the component
        }
    },[reset])

    useEffect(()=>{
        mountedRef.current = true

        window.addEventListener('message', handleIframeTask,);
        
        return()=>{
            mountedRef.current = false
            setLoading(true);
            setWaiver(null);
            window.removeEventListener('message', handleIframeTask);
        }
    },[handleIframeTask])

    useEffect(()=>{

        /**Function which checks to see if a passed in user prop has the signed_waiver field or if props.waiverStatus has been passed in and checks signed/not */
        const checkSignature =()=>{ 
            if(mountedRef.current === true){
                let signed;
                if(props.waiverStatus===1) signed = props.waiverStatus
                else if(props.user.hasOwnProperty("has_signed_waiver")) signed = props.user.has_signed_waiver
                if(signed === 1) {
                    window.removeEventListener('message', handleIframeTask);
                    setSignedWaiver(true);
                    if(props.reset) props.reset(1);
                }
                else {
                    setSignedWaiver(false);
                    window.addEventListener('message', handleIframeTask);
                }
            }
        }

        const getWaiver=async()=>{
            if(mountedRef.current === true){
                let id = props.user.id;
                setWaiver(REACT_APP_API_URL + "/waiver/" + id)
                setLoading(false)
            }
        }

        if(mountedRef.current === true) setLoading(true)

        if(props.noBackground && props.noBackground === true && mountedRef.current === true){
            setWaiverClassName("pos-waiver-home-card")
        }

        if(props){
            checkSignature();
            getWaiver();
        }
        
    },[props, signedWaiver, handleIframeTask, REACT_APP_API_URL]);

//#endregion useState, useEffect


//#region Handlers
    
    const openWaiver=()=>{
        setWaiverHideShow(!waiverHideShow)
    }

//#endregion Handlers

    if(loading === true){
        return (
            null
        )
    }

  return (
    <>
        {!signedWaiver &&
            <>
                {props?.type==="button" && 
                    <Button data-cy="waiver-btn" variant={props.variant || "danger"} className={`sign-waiver-btn ${props.classes}`} onClick={openWaiver}>{props.btntxt}</Button>
                }
                {!props?.type &&
                    <Card className={waiverClassName} data-cy="waiver-banner-unsigned">
                        <p>{props.msg}</p>
                        <Button data-cy="waiver-btn" className="sign-waiver-btn" onClick={openWaiver}>{props.btntxt}</Button>
                    </Card>
                }
            </>
        }

        {signedWaiver===true && !props.hide &&
            <Card className={waiverClassName} data-cy="waiver-banner-signed">
                <p>Signed Waiver on File</p>
            </Card>
        }

        <Modal dialogClassName="waiver-modal" show={waiverHideShow} onHide={openWaiver}> 
        <Modal.Header><CloseButton onClick={e=>setWaiverHideShow(false)}/></Modal.Header>
        <Modal.Body>
            <iframe 
                src={waiver} 
                name="waiver"
                className="waiver-iframe"
                title="Signable User Waiver"
            />
        </Modal.Body>
        </Modal>
    </>
  )
}
