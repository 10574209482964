import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

import styles from './Events.module.scss';

export const EventDisplay =({day, canEditEvent=false, family=[], profileUser, authUserId, ...props})=>{

    const [showExtraDetails, setShowExtraDetails]=useState(null);

    const handleRowClick=(id)=>{
        if(showExtraDetails === id) setShowExtraDetails(null);
        else setShowExtraDetails(id);
    }

    const findForUser=(eachEvent)=>{
        if(family.length === 0) return null;
        else{
            let familyIds = family.map((member)=>member.user_id);
            let theUser = eachEvent?.users?.find((each)=>familyIds.includes(each.id))
            debugger
            if(theUser?.id === profileUser.id && authUserId === profileUser.id) return null;
            else return `${theUser.first_name} ${theUser.last_name}`
        }
    }

    return(
        <div className={styles["event-display-wrapper"]}>
            <h6>
                {day.date}
            </h6>
            <hr />
            {day?.events?.map((event)=>{
                let forUser = findForUser(event);
                console.log(forUser);
                return(
                <div className={styles["each-event"]}>
                    <div className={styles["main-details"]} onClick={()=>{handleRowClick(event.id)}}>
                        <p className={styles.bold}>
                            <span>
                                {format(new Date(event.start_datetime), "hh:mm aaa")} - {format(new Date(event.end_datetime), "hh:mm aaa")}
                            </span>
                            <span>
                                {event.name}
                            </span>
                        </p>
                        <p>
                            {forUser ? 
                                <span>
                                    For {forUser} 
                                </span>
                                :
                                <>
                                    {authUserId === profileUser.id ? 
                                        <span>
                                            For Me
                                        </span>
                                    :
                                        <span>
                                            For {profileUser.first_name} {profileUser.last_name}
                                        </span>
                                    }
                                </>
                            }
                            <span>
                                <i className="fas fa-chevron-double-down" /> More Details
                            </span>
                        </p>
                    </div>
                    {showExtraDetails===event.id &&
                        <div className={styles["event-extra-details"]}>
                            <span className={styles["head-labels"]}>
                                Location
                            </span>
                            <p>
                                {event?.location_name}
                            </p>
                            <span className={styles["head-labels"]}>
                                Event Type
                            </span>
                            <p>
                                {event?.event_type_name}
                            </p>
                            {event.description ? 
                                <>
                                    <span className={styles["head-labels"]}>
                                        Description
                                    </span>
                                    <p dangerouslySetInnerHTML={{__html: event.description}} />
                                    {canEditEvent &&
                                        <p>
                                            <a href={`/p/events/${event.id}`}>View Event</a>
                                        </p>
                                    }
                                </>
                            :
                                <span>
                                    No Event Description Found
                                </span>
                            }
                        </div>
                    }
                </div>
            )})}
        </div>
    )
}

export default EventDisplay;