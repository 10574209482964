import React, {useRef, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Row, Col, Button, Card} from 'react-bootstrap';

import { Payment } from './Payment';
import { addToCart, saveLogging } from '../../../../../../utils/thunks';
import styles from './Step3.module.scss';

export const Step3 = (props) => {
    const {saveStepValues, disableNext} = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(state => state.auth.user.profile);

    const subtotalRef = useRef();

    const [loading, setLoading] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [customFields, setCustomFields] = useState();
    const [showPayment, setShowPayment] = useState(false);
    const [selectedPrice, setSelectedPrice] = useState(props?.default_variant_price || null);
    const [selectedVariant, setSelectedVariant] = useState(0);

    const addToCartHandler = async (e) => {
        if (props.product_id && (selectedVariant || props.default_variant_id)) {

            setLoading(true);

            const new_items = [];
            selectedUsers.forEach(user => {
                new_items.push({
                    //order_item_id: null,
                    id: props.product_id,
                    parent_id: props.product_id,
                    variant_id: selectedVariant || props.default_variant_id,
                    activation_fee: props.variants.find(v=>+v.id === +(selectedVariant || props.default_variant_id)).activation_fee || 0,
                    type: 0,
                    qty: 1,
                    event:{
                        event_id: props.id,
                        for_user_id: user.id,
                        custom_fields: customFields?.[user.id] || []
                    }
                });
            });

            // REMOVE LATER: Extra logging to help debug order saving issues
            dispatch(saveLogging(`dispatch addToCart Items 285`));
            dispatch(addToCart(new_items, false, props.register_id, user.id, ()=>{
                if (props.checkout_url) {
                    if (props.close) props.close();
                    window.location.href=props.checkout_url;
                }                        
            }));

            setLoading(false);
        }
    }

    const selectVariantHandler = (e, variant_id) => {
        e.preventDefault();
        e.stopPropagation();
        const _variant = props.variants.find(v=>+v.id === +variant_id);
        setSelectedPrice(_variant.bill_interval && _variant.activation_fee ? _variant.activation_fee : _variant.price);
        setSelectedVariant(variant_id);
        setShowPayment(false);
        saveStepValues({variant_id: variant_id});
    }

    useEffect(() => {
        if (props.stepValues?.selectedUsers) setSelectedUsers(props.stepValues.selectedUsers);
        else setSelectedUsers([{id: user.id, first_name: user.first_name, last_name: user.last_name}]);
        if (props.stepValues?.customFields) setCustomFields(props.stepValues.customFields);
    }, [user, props.stepValues]);

    useEffect(() => {
        disableNext(true); // payment component will enable it if needed
    }, [disableNext]);

    useEffect(() => {
        return () => {
            setLoading(false);
            setSelectedUsers([]);
            setCustomFields(null);
            setShowPayment(false);
        }
    }, []);

    if (loading) return (<p>Loading...</p>);

    return (
        <>
            <div>
                <p>
                    <label className="form-label">Registering for this event:</label>
                </p>
                <Row>
                {selectedUsers.map((user, i) => (
                    <Col sm={12} lg={6} key={`selected-user-${user.id}-${i}`}>
                        <span className="bold">{user.first_name} {user.last_name} {user?.group_member_role}</span>
                        <br/>
                        {customFields && customFields?.[user.id] && customFields[user.id].map((field, i) => (
                            <div key={`custom-f-${i}-${user.id}-${field.custom_field_id}`}>
                                {field.custom_field_label}: {field.label}
                                <br/>
                            </div>
                        ))}
                        {(props.default_variant_price || props.variants?.length>0) && <>Fee: ${selectedPrice}</>}
                    </Col>
                ))}
                </Row>
                {(props.default_variant_price || props.variants?.length>0) &&
                    <>
                        <hr/>
                        <h5 className="title">
                            Subtotal: <span ref={subtotalRef}>${(+selectedPrice*selectedUsers.length).toFixed(2)}</span>
                        </h5>
                        <hr/>

                        {props.variants?.length>1 &&
                            <>
                                <Row>
                                    {props.variants.map((variant, i) => (
                                        <Col key={`variant-${variant.id}-${i}`}>
                                            <Card 
                                                className={`${styles["variant"]} ${+selectedVariant === +variant.id || (selectedVariant===0 && variant.name==="Default") ? styles.selected : ""}`}
                                                onClick={(e)=>selectVariantHandler(e, variant.id)}
                                            >
                                                <div className="bold">{variant.name==="Default" ? "Pay in Full" : variant.name}</div>
                                                {!variant.bill_interval && <>${variant.price}</>}
                                                {variant.bill_interval &&
                                                    <>
                                                        {variant.activation_fee &&
                                                            <span>${variant.activation_fee} now then </span>
                                                        }
                                                        {variant.bill_num_times && 
                                                            <span>{variant.bill_num_times} installments of </span>
                                                        }
                                                        {+variant.interval_quantity>1 &&
                                                            <span>${variant.price} every {variant.interval_quantity} {variant.bill_interval==="m" ? 'month(s)' : 'year(s)'}</span>
                                                        }
                                                        {+variant.interval_quantity<=1 &&
                                                            <span>${variant.price}/{variant.bill_interval==="m" ? 'month' : 'year'}</span>
                                                        }
                                                    </>
                                                }
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                                <hr/>
                            </>
                        }

                        {props.checkout_url &&
                            <Button size="lg" variant="secondary" onClick={(e)=>addToCartHandler(e)}>Add to Cart</Button>
                        }
                        <Button size="lg" variant="primary" onClick={()=>setShowPayment(true)}>Pay Now</Button>
                        {showPayment &&
                            <Payment {...props} price={selectedPrice}  />
                        }
                    </>
                }
            </div>
        </>
    );
}