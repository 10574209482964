import React, { useState, useCallback } from 'react';
import { Typeahead } from './Typeahead';

const OrderStatusesTypeahead = ({
    async = false,
    paginated = false,
    multiple = true, 
    ...props
}) =>{
    const [ loading, setLoading ] =useState(true);

    const getOrderStatuses=useCallback(async()=>{
        let responseObject = {
            data: null,
            errors: null
        }
        try{
            //one day there will be an endpoint here - for now we're only using these order statuses and don't need the others until online pickup is implemented.  
            responseObject.data = [
                {id: 2, name: "Completed"},
                {id: 1, name: "Pending"},
                {id: 3, name: "Cancelled"},
                {id: 4, name: "Refunded"},
                {id: 5, name: "Partially Refunded"}
            ]

        }catch(ex){
            console.error(ex);
            responseObject.errors = ex;
        }
        setLoading(false);
        return responseObject;
    },[]);

    return(
        <Typeahead 
            {...props}
            id="order-statuses"
            makeRequest={getOrderStatuses}
            async={async}
            multiple={multiple}
            paginated={paginated}
            placeholder = {loading ? "Loading Statuses..." : props.placeholder || "Search Order Statuses"}
        />
    )
}

export default OrderStatusesTypeahead;