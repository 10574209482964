import Request from './Api';

const Statuses={
    getStatuses: async (props) => {
        return(
            Request({
                url: "/order/status",
                method: "GET",
                data: props
            })
        )
    }
}

const Order = {
    Statuses
}

export default Order;