import React, { useState, useEffect } from 'react';
//import Card from 'react-bootstrap/Card';


import Card from './Card';

import "./Products.scss";

export const Products = (props) => {
    let services = props.services || false;

    const [price, setPrice] = useState(null);
    const [subItem, setSubItem]=useState(null);
    const [item, setItem]=useState(props.item);

    useEffect(() => {
        if (props.price){
            if (Array.isArray(props.price)){
                //setPrice("$"+ (Math.min( ...props.price )).toFixed(2)+" - $"+ (Math.max( ...props.price )).toFixed(2));
                setPrice(`Starting from $${Math.min(...props.price).toFixed(2)}`);
            } else {
                setPrice("$"+ (+props.price).toFixed(2));
            }
        }
    },[props.price]);

    useEffect(()=>{
        let _item = props.item;
        if(props.item.product_status_id === 1){
            //if there are more than one variant, it will be shown after clicking on the variant picker.  
            if(props?.item?.product_variants?.length === 1){
                setSubItem(props.item.product_variants[0]);
            } else {
                setSubItem(props.item);
            }
        }
        if (props.events) _item.events = props.events;
        setItem(_item)
    },[props.item, props.events]);

    let variant = props.item;
    if (props?.item?.product_variants?.length === 1) variant = props.item.product_variants[0];

    return (
        <Card 
            title={props.name}
            onClick={()=>props.click(item, props.type)}
            styles={`${services ? 'services' : ''} ${Array.isArray(props.price)?"":"single"}`}
        >
            {price &&
                <div className="price" data-cy="product-price">{price} {props.isActivationFee && <span className="subscription-text">activation Fee</span>}</div>
            }
            {subItem && subItem?.hasOwnProperty("bill_interval") && subItem?.hasOwnProperty("interval_quantity") &&
                <div className="subscription-text">
                    {subItem?.bill_interval ==="m" && 
                        <p>
                            {+subItem.activation_fee>0 ?
                                <>
                                    + ${subItem.price} subscription<br/>
                                </>
                            : "Subscription"}
                            {subItem?.interval_quantity && subItem?.interval_quantity > 1 ?
                                ` billed every ${subItem?.interval_quantity} month${subItem?.interval_quantity > 1 ? "s" : ""}.` 
                            :
                                " billed monthly."
                            }
                            {subItem?.bill_num_times ? 
                                <>
                                    <br/>
                                    {`${subItem?.bill_num_times} time${subItem?.bill_num_times > 1 ? "s" : ""} in total.`}
                                </>
                            :
                                <>
                                    <br/>
                                    until cancelled.
                                </>
                            }
                        </p>
                    }

                    {subItem?.bill_interval ==="y" &&
                        <p>
                            {subItem?.bill_num_times ?
                                " billed on purchase and will not recur."
                            :
                                " billed annually until cancelled."
                            }    
                        </p>
                    }
                    {subItem?.subscription_type_name &&
                        <p>
                            - {subItem?.subscription_type_name} Subscription 
                            {subItem?.subscription_max_users && subItem?.subscription_max_users > 1 ? 
                                ` (max users: ${subItem?.subscription_max_users}).`
                            : 
                                "."
                            }
                        </p>
                    }
                </div>
            }
            {props.image && !services && 
                <div className="ali-img-cen" data-cy="product-card-image"><img src={props.image} alt="" title= "" className="pos-img"/></div>
            }
            {props.description && !services &&
                <p className="product-description" data-cy="product-card-description">{props.description}</p>
            }
        </Card>
    );
}