import React from 'react';
import Request from './Api';

// get transactions
const getAll = async (props) => {
    return (
        Request({
            url: "/transactions",
            data:props,
            method: "POST"
        })
    );
}

// get transactions for a user
const get = async (props) => {
    return (
        Request({
            url: "user/transactions",
            data:props,
            method: "POST"
        })
    );
}

const getReports =async (props)=>{
    let url;
    if(props && props.url){url = props.url}
    delete props.url;
    return(
        Request({
            url: "/transaction/reports" + (url ? "/" + url : ""),
            method: "POST",
            data: props
        })
    )
}

const Reports = {
    getProducts: async props =>{
        return (
            Request({
                url: "/transactions/reports/products",
                method: "POST",
                data: props
            })
        )
    },
    getCategories: async props=>{
        return(
            Request({
                url: "transactions/reports/categories",
                method: "POST",
                data: props
            })
        )
    },
    getPaymentMethods: async props=>{
        return(
            Request({
                url: "transactions/reports/payment_methods",
                method: "POST",
                data: props
            })
        )
    },
    getCash: async props=>{
        return(
            Request({
                url: "transactions/reports/cash",
                method: "POST",
                data: props
            })
        )
    },
    getUsers: async props=>{
        return(
            Request({
                url: "reports/users",
                method: "POST",
                data: props
            })
        );
    },
    getSettlements: async props=>{
        return(
            Request({
                url: "/transactions/reports/settlement",
                method: "POST",
                data: props
            })
        )
    },
    getSubscriptions: async props=>{
        return(
            Request({
                url: "/transactions/reports/subscriptions",
                method: "POST",
                data: props
            })
        );
    },
    getCoupons: async props =>{
        return(
            Request({
                url: "/transactions/reports/coupons",
                method: "POST",
                data: props
            })
        )
    },
    getTips: async props =>{
        return(
            Request({
                url: "/transactions/reports/tips",
                method: "POST",
                data: props
            })
        )
    },
    getFormSubmissions: async props =>{
        return(
            Request({
                url: "/reports/form_submissions",
                method: "POST",
                data: props
            })
        )
    }
}

const Transactions = {
    get, Reports, getReports, getAll
}

  
export default Transactions;
