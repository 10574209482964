import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

import { default as UpcomingEventList } from "../../containers/POS/Events/List";
import DisplayQRCode from "../../components/common/DisplayQRCode";
import UpcomingSchedule from '../Dashboard/UpcomingSchedule';
import UnpaidEvents from '../Dashboard/UnpaidEvents';
import MembershipDetail from '../Dashboard/MembershipDetail';
import Wallet from '../Dashboard/Wallet';
import FamilyGroup from '../Dashboard/FamilyGroup';
import Livestreams from '../Dashboard/Livestreams';
import Waiver from '../User/Waiver';
import { DobPrompt } from '../User/DobPrompt';
import ProfileCard from "../Dashboard/ProfileCard";
import { authCheck, authUserHasModuleAccessMany } from "../../utils/auth";
import { updateAuthUser } from '../../utils/thunks';
import { loadCompanyConfig, loadFeaturesAvailable } from '../../utils/thunks';

import './Home.scss';

//permissions for home could be done with widgets, but if we make it highly customizable, that could be a LOT of widgets.  
//However, doing it by feature allows us another level of control in a more blanket style, rather than individual pieces 
    //and allows us to make smaller pieces of customization without making 100s of widgets that need to be recreated on each environment
//It also allows us the potential to do double permissions  examples:
    //does the company have access to livestreams AND transactions - for a purchase link vs a free view link? 
    //does the company have access to events AND services - See a combined list that includes both
//widgets also currently don't seem to be taking into account features purchased with their allowability.

//these widgets already have permissions; extra check
const WALLET_MODULE_ID = 216;
const SUBSCRIPTION_MODULE_ID = 75;
const OUTSTANDING_CHARGES = 76;
const EVENT_SCHEDULE = 77;

const PERMISSION_ARRAY = [WALLET_MODULE_ID, SUBSCRIPTION_MODULE_ID, OUTSTANDING_CHARGES, EVENT_SCHEDULE];

export const Home = () => {
    let history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user);
    const companyId = useSelector(state => state.auth?.user?.company_id);
    const companyConfig = useSelector(state => state.company?.config);
    const featuresAvailable = useSelector(state => state.permissions?.features)
    const defaultPatronRegister =useSelector(state =>state.pos.register);
    const [reload, setReload] = useState(true);
    const [waiverStatus, setWaiverStatus]=useState(0);
    const [userHasModulePermission, setUserHasModulePermission]=useState(null);

    useEffect(() => {
        let user_info = authCheck(history);
    },[history]);

    useEffect(()=>{
        if(!companyConfig) dispatch(loadCompanyConfig());
    },[companyConfig, dispatch]);

    useEffect(()=>{
        if(!featuresAvailable) dispatch(loadFeaturesAvailable());
    },[featuresAvailable, dispatch]);

    useEffect(()=>{
        const getPermissions = async()=>{
            try{
                let response = await authUserHasModuleAccessMany(PERMISSION_ARRAY, user.profile.id);
                setUserHasModulePermission(response);
            }catch(ex){console.error(ex)}
        }

        if(user) getPermissions();
    },[user])

    useEffect(() => {
        // this probably doesn't need to get called every single page refresh - or if it is called, cache on the back end?
        // it does need frequent updates for the unpaid events and other details, all need to be updated with latest info

        // if there's no user in redux yet, don't try to load the profile yet
        if (user && reload) {
            dispatch(updateAuthUser());
            setReload(false);
        }
    },[user, reload, dispatch]);
    
    const resetWaiver=(status)=>{
        if(waiverStatus !== status){
            dispatch(updateAuthUser());
            setWaiverStatus(status);
        }
    }

    const resetUser = () => {
        dispatch(updateAuthUser());
    }

    if (!user?.profile) return <div data-cy="home-loading">Loading...</div>
    return (
      <>
        {companyConfig?.requires_waiver === "true" &&
            <Waiver 
                reset={resetWaiver}
                waiverStatus={waiverStatus}
                btntxt={"Sign Waiver Now"} 
                user={user.profile} 
                msg={"You do not have an electronic waiver on file - please click to sign "} 
                hide={true}
            />
        }
      <Container fluid>
        <div className="content-card">
            <Row className="body home">
                {(companyConfig?.requires_dob===true || companyConfig?.requires_dob==="true") &&
                    <Col xs="12">
                        <DobPrompt
                            message="You do not have a date of birth on file - please click here to submit yours"
                            onClose={resetUser}
                            user={user.profile}
                        />
                    </Col>
                }
            {/* {featuresAvailable && featuresAvailable[14]?.available &&
                <Col xs="12" className="equ-height mt-3">
                    <div className="prof-bg">
                        <h4 className="prof-he">Special Events</h4>
                        <hr className="prof-he-line" />
                        <Livestreams />
                    </div>
                </Col>
            } */}
            {featuresAvailable && featuresAvailable[3]?.available &&
                <Col xs="12" className="d-md-none p-0 home-card"> {/* hidden on md and lg screens */}
                    <ProfileCard 
                        user={user.profile} 
                        components={featuresAvailable && featuresAvailable[17]?.available && [<DisplayQRCode user_id={user.profile.id} />]} 
                    />
                </Col>
            }
            {featuresAvailable && featuresAvailable[11]?.available &&
                <Col md="12" className="equ-height home-card" data-cy="home-eventList">
                    <UpcomingEventList home={true} />
                </Col>
            }
            {featuresAvailable && featuresAvailable[37]?.available &&
            userHasModulePermission && userHasModulePermission[SUBSCRIPTION_MODULE_ID] &&
                <Col md="6" className="equ-height home-card">
                    <div className="prof-bg">
                        <h4 className="prof-he">Membership Details</h4>
                        <hr className="prof-he-line" />
                        <MembershipDetail user={user.profile} />
                    </div>
                </Col>
            }
            {featuresAvailable && featuresAvailable[11]?.available && //should maybe also add transactional?
            userHasModulePermission && userHasModulePermission[OUTSTANDING_CHARGES] &&
                <Col md="6" className="equ-height home-card">
                    <div className="prof-bg">
                        <h4 className="prof-he">Unpaid Event Charges</h4>
                        <hr className="prof-he-line" />
                        {companyConfig?.default_patron_register &&
                            <UnpaidEvents user={user.profile} register={companyConfig?.default_patron_register} />
                        }
                        {!companyConfig?.default_patron_register &&
                            "No default patron register set."
                        }
                    </div>
                </Col>
            }
            {featuresAvailable && featuresAvailable[35]?.available && 
            userHasModulePermission && userHasModulePermission[WALLET_MODULE_ID] &&
                <Col md="3" className="equ-height home-card">
                    <div className="prof-bg">
                        <h4 className="prof-he">Wallet</h4>
                        <hr className="prof-he-line" />
                        <Wallet user={user.profile} />
                    </div>
                </Col>
            }
            {featuresAvailable && featuresAvailable[11]?.available &&
            userHasModulePermission && userHasModulePermission[EVENT_SCHEDULE] &&
                <Col md="6" className="equ-height home-card">
                    <div className="prof-bg">
                        <h4 className="prof-he">Upcoming Schedule</h4>
                        <hr className="prof-he-line" />
                        <UpcomingSchedule user_id={user.profile.id} />
                    </div>
                </Col>
            }
            {featuresAvailable && featuresAvailable[8]?.available &&
                <Col md="3" className="equ-height home-card">
                    <div className="prof-bg">
                    <FamilyGroup user={user.profile} />
                    </div>
                </Col>
            }
            {/* <Col xs="12" className="equ-height home-card">
                <div className="prof-bg">
                <h4 className="prof-he">Special Events</h4>
                <hr className="prof-he-line" />
                <Livestreams />
                </div>
            </Col> */}
            </Row>
        </div>
      </Container>
      </>
    );
}