import Request from './Api';

// get registers
const register = {

    get: props => {
        return Request({
            url: "/register" + (props.is_patron_register ? "/patron" : ""),
            method: "POST",
            data: props,
        });
    },

    edit: props => {
        return (
            Request({
                url: "/register/edit",
                method: "POST",
                data: props
            })
        );
    }
}


// Cart
const order = {
    // saves the order
    save: props =>{
        return (
            Request({
                url: "/order/add",
                method: "POST",
                data: props,
            })
        );
    },
    // update the order
    update: props =>{
        return (
            Request({
                url: "/order/update",
                method: "PUT",
                data: props,
            })
        );
    },    
    get: props =>{
        return (
            Request({
                url: "/order" + (props.id ? "/order/"+props.id : ""),
                method: props.method || "GET",
                data:props,
            })
        );
    },
    latestOpen: props =>{
        return (
            Request({
                url: "/order/latest_open",
                method: "POST",
                data: props,
            })
        );
    },
    // loads order lines
    getItems: async props =>{
        const order=await Request({
            url: "/order/"+props.id,
            method: "GET",
            data: props,
        });
        if (order.data?.[0]?.items) return Promise.resolve({data:order.data[0].items});
        else return order;
    },
    // save order lines
    addItems: props =>{
        return (
            Request({
                url: "/order/add",
                method: "POST",
                data: props,
            })
        );
    },
    // saves an order's new custom status
    logStatus: props =>{
        return (
            Request({
                url: "/order/log_status",
                method: "POST",
                data: props,
            })
        );
    }
}

// Local storage
const local = {
    get: (key) =>{
        return new Promise((resolve, reject) => {
            resolve(JSON.parse(localStorage.getItem(key)));
        });
    },
    save: (key,value,type=0) =>{
        return new Promise((resolve, reject) => {
            let item=localStorage.getItem(key);
            switch(type){
                case 1: // push to key
                    if (item){
                        item=JSON.parse(item);
                        let new_item=[];
                        if (!Array.isArray(item)) new_item.push(item);
                        else new_item=item;
                        new_item.push(value);
                        value=new_item;
                    }
                    break;
                case 2: // update value
                    if (item){
                        item=JSON.parse(item);
                        let new_item;
                        if (!Array.isArray(item)) new_item=value;
                        else{
                            new_item=[];
                            item.forEach(i=>{
                                if (i.hash===value.hash) new_item.push(value);
                                else new_item.push(i);
                            });
                            value=new_item;
                        }
                    }
                    break;
                default:
                    break;
            }
            localStorage.setItem(key,JSON.stringify(value));
            resolve(Array.isArray(value)?value:[value]);
        });
    },
    update: (key,value) =>{
        return local.save(key,value,2);
    },
    append: (key,value) =>{
        return local.save(key,value,1);
    },
    remove: (key,value) =>{
        return new Promise((resolve, reject) => {
            if (!value) resolve(localStorage.removeItem(key));
            else {
                let item=localStorage.getItem(key);
                if (item){
                    item=JSON.parse(item);
                    if (!Array.isArray(item) && JSON.stringify(item)===JSON.stringify(value)) resolve(localStorage.setItem(key,"[]"));
                    else {
                        const new_item=item.filter(i=>JSON.stringify(i)!==JSON.stringify(value));
                        resolve(localStorage.setItem(key,JSON.stringify(new_item)));
                    }
                } else reject("Item not found!");
            }
        });
    }
}

const addRefund=async(props)=>{
    return(
        Request({
            url: "payment/refund/add",
            method: "POST",
            data: {...props}
        })
    )
}

const orderRefund=async(props)=>{
    return(
        Request({
            url: "payment/process/refund",
            method: "POST",
            data: {...props}
        })
    )
}

// Payment
const payment = {
    process: props =>{
        let header_data=null;
        if (props.hash){ // this is used to encrypt/decrypt the data (for manager discounts)
            // re-do the header to include the hash
            let token="";
            let localuser = localStorage.getItem("user");
            if (localuser) {
                try{
                    localuser=JSON.parse(localuser);
                    token=localuser.token;
                }catch(error){}
            }
            header_data = {'Authorization': token, 'X-Anti-Spam-Hash': props.hash};
            delete props.hash;
        }

        return (
            Request({
                url: "/payment/process",
                method: "POST",
                data: props,
                headers: header_data || undefined,
            })
        );
    },
    processRefund: props =>{ // refund a transaction
        return (
            Request({
                url: "/payment/refund",
                method: "POST",
                data: props,
            })
        );
    },
    processVoid: props =>{ // void a transaction
        return (
            Request({
                url: "/payment/void",
                method: "POST",
                data: props,
            })
        );
    },
    sendReceipt: props =>{
        return (
            Request({
                url: "/payment/email",
                method: "POST",
                data: props,
            })
        );
    },
    card: props =>{
        return (
            Request({
                url: "/payment/nmi_process_credit_card",
                method: "POST",
                data: props,
            })
        );    
    },
    terminal: props => {
        return (
            Request({
                url: "/payment/nmi_terminal_transaction",
                method: "POST",
                data: props,
            })
        );
    },
    terminalStatus: props => {
        return (
            Request({
                url: "/payment/nmi_async_status/"+props.transaction_id,
                method: "GET",
                data: props,
            })
        );
    },
    profile: props => {
        return (
            Request({
                url: "/payment/nmi_charge_payment_profile",
                method: "POST",
                data: props,
            })
        );
    },
    cash: props => {
        return (
            Request({
                url: "/payment/record_cash_payment",
                method: "POST",
                data: props
            })
        );
    },
    token: props => {
        return (
            Request({
                url: "/payment/redeem_token",
                method: "POST",
                data: props
            })
        );
    },
    service_tokens: props => {
        return (
            Request({
                url: "/payment/redeem_service_tokens",
                method: "POST",
                data: props
            })
        );
    },
    refund: props=>{
        return(
            Request({
                url: 'payment/nmi_refund_transaction',
                method: 'POST',
                data: props
            })
        )
    },
    split_payment: props=>{
        return(
            Request({
                url: "/order/split",
                method: "POST",
                data: props,
            })
        )
    },
    cancel_split: props=>{
        return(
            Request({
                url: "/order/cancel_split",
                method: "POST",
                data: props
            })
        )
    }
}

// Print Location
const printLocation = {
    getNext: props =>{
        return (
            Request({
                url: "/print_location",
                method: "POST",
                data: props,
            })
        );    
    },
    complete: props =>{
        return (
            Request({
                url: "/print_location/complete",
                method: "POST",
                data: props,
            })
        );    
    },
};

// Print Location
const registerGroups = {
    get: props =>{
        return (
            Request({
                url: "/register/group",
                method: "POST",
                data: props,
            })
        );    
    },
};


const Pos = {
    register, local, payment, order, orderRefund, addRefund, printLocation, registerGroups
}
  
export default Pos;
