import React from "react";
import styles from '../TableWithDropdown/TableWithDropdown.module.scss';

import { format } from "date-fns-tz/format";

/**
 * 
 * @param {*} row The data from the selectd row being passed in
 * @param {*} hiddenHeadings If true, will hide the headings row
 * @param {*} expandData The data that will be shown in the expanded row - formatted like tableHeadings(i.e. {[{key: "tip", label: "Tip"}, {key: "user_id", label: "Date"}]}) 
 */
export const ExpandedRow = ({
    row={},
    hiddenHeadings=false,
    expandData=[],
    showExpanded=false,
    expandedRowId=null,
    dateFormat="MM/dd/yy"
})=>{
    
    return(
        <>
            {!hiddenHeadings &&
                <tr className={`
                    ${showExpanded && expandedRowId===row?.id ? "" : styles.hidden}
                    ${styles.headings}
                `}>
                    {expandData?.map((expanded, i)=>{
                        let isString = typeof expanded?.label === "string" ? true : false;
                        return(
                            isString ?
                                <td key={`expanded-row-${expanded.label}-${i}`}>{expanded.label}</td>
                            :
                                <>
                                    {expanded?.label}   
                                </>
                        )
                    })}
                </tr>
            }
            <tr className={showExpanded && expandedRowId === row?.id ? "" : styles.hidden}>
                {expandData?.map((expanded, k)=>(
                    <td key={`expanded-cell-${row.id}-${k}`}>
                        {expanded?.key?.toLowerCase()?.includes("date") || expanded?.key?.toLowerCase()?.includes("updated_at") || expanded?.key?.toLowerCase()?.includes("created_at") ?
                            <>
                                {row[expanded?.key] && format(new Date(row[expanded?.key]), dateFormat)}
                            </>
                        :
                            <>
                                {expanded?.key?.includes("multiple-") && expanded.hasOwnProperty("multiple") ?
                                    <>
                                        {expanded?.multiple?.map((multi, k)=>(
                                            <span key={`heading-multiple-${multi}-${k}`}>
                                                {row[multi]}{" "}
                                            </span>
                                        ))}
                                    </>
                                :
                                    <>
                                        {row[expanded?.key]}
                                    </>
                                }
                            </>
                        }
                    </td>
                ))}
            </tr>
        </>
    )
}